/*-----------------------------------------------
|   Navbar
-----------------------------------------------*/
.shadow-bottom{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2) !important; }

/*-----------------------------------------------
|   Navbar vertical
-----------------------------------------------*/
$navbar-vertical-width: 12.625rem;
$vartical-navbar-padding: 1.5rem;

.navbar-top{
  top: 0;
  z-index: 1020;
}
.navbar-vertical-wrapper{
  position: absolute;
  left: 15px;

  + .content{
    margin-left: $navbar-vertical-width + map_get($spacers, 5);
  }
}
.navbar-vertical{
  position: -ms-device-fixed !important;
  padding: 0;
  z-index: 1019;
  top: $top-nav-height;
  width: 100vw;
  .navbar-brand{ display: none; }
  .navbar-collapse{
    flex-direction: column;
    margin-top: map_get($spacers, 1);
    > * {
      width: 100%;
      overflow: hidden;
    }
  }
  /*-----------------------------------------------
  |   Navbar nav
  -----------------------------------------------*/
  .navbar-nav{
    width: 100%;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    .nav-link{
      color: $gray-700;
      @include media-breakpoint-up('lg'){ padding: 0.35rem 0; }
      @include hover-focus{
        color: $gray-1000;
        &.dropdown-indicator:after{ border-color: $gray-1000; }
      }
    }
    .nav-link-icon{
      width: $vartical-navbar-padding;
      font-size: map_get($font-sizes, 0);
    }
    .nav{
      flex-flow: column nowrap;
      font-size: 0.8125rem;
      .nav-item{
        .nav-link{
          padding: 0.2rem $vartical-navbar-padding;
        }
        &:last-child{
          margin-bottom: 0.35rem;
        }
      }
      .nav{
        .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 1.75; } }
        .nav{ .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 2.75; } } }
      }
    }
  }
}

/*-----------------------------------------------
|   Break 
-----------------------------------------------*/

$breaks: ('xxl', 'xl', 'lg', 'md', 'sm', 'xs');

@for $i from 1 through length($breaks){
  $item: nth($breaks, $i);
  $j: '';
  @if length($breaks) != $i{
    $j: $i + 1;
    $down: nth($breaks, $j);
    @include media-breakpoint-down($down){
      @for $k from $j through length($breaks){
        $inneritem: nth($breaks, $k);
        @if length($breaks) != $k{
          .navbar-vertical.navbar-expand-#{$item}{
            @include media-breakpoint-only($inneritem){ 
              position: fixed;
              max-width: map_get($container-max-widths, $inneritem) !important; 
              width: 100%;
            }
            & + .content{
              padding-top: $top-nav-height;
              .navbar-top {
                position: fixed;
                top: 0;
                @include media-breakpoint-only($inneritem){
                  width: 100%;
                  max-width: map_get($container-max-widths, $inneritem) !important;
                }
              }
            }
            
          }
        }
      }
      .container-fluid{
        .navbar-vertical.navbar-expand-#{$item}{
          width: 100%;
          max-width: 100vw !important;
          & + .content .navbar-top{
            width: 100%;
            max-width: 100vw !important;
          }
        }
      }
    }
    .navbar-vertical.navbar-expand-#{$item}{
      @include media-breakpoint-up($item){
        position: fixed;
        width: 100%;
        top: 0;
        overflow-y: auto;
        max-height: 100vh;
        display: block;
        max-width: $navbar-vertical-width;
        .navbar-brand{
          display: block;
          text-align: center;
        }
        & + .content{
          margin-left: $navbar-vertical-width + map_get($spacers, 5);
          .navbar-top{
            position: sticky;
            .navbar-brand, .navbar-toggler{ display: none;}
           }
        }
      }
      .navbar-collapse{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2); }
      @include media-breakpoint-down($down){
        
        & + .content .navbar-top .navbar-toggler{ display: block; }
        
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
        .navbar-nav{
          padding-left: $grid-gutter-width / 2;
          padding-right: $grid-gutter-width / 2;
        }
        .navbar-collapse{
          &.show{
            max-height: calc(100vh - #{$top-nav-height});
            overflow-y: auto;
          }
          .btn{
            width: calc(100% - #{$grid-gutter-width});
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
@include media-breakpoint-only('xs'){
  .navbar-vertical{
    position: fixed;
    width: 100%;
    max-width: 100vw !important; 
  }
  .content{ padding-top: $top-nav-height; }
  .navbar-top {
    position: fixed;
    width: 100%;
    max-width: 100vw !important;
  }
}
