/*-----------------------------------------------
|   Button Reveal
-----------------------------------------------*/
.btn-reveal-trigger{
  @include hover-focus {
    .btn-reveal{
      box-shadow: $btn-reveal-hover-shadow;
      @include hover-focus-active{ color: $dark !important; }
    }
  }
  .btn-reveal{
    &:active:focus, &:active, &:focus{
      box-shadow: none;
      color: $dark !important;
      background-color: darken($white, 10%);
      border-color: darken($white, 12.5%);
    }
  }
  .dropdown-toggle:after{
    display: none;
  }
}

/*-----------------------------------------------
|   Falcon buttons
-----------------------------------------------*/
$theme-falcon-btn-colors: (
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'default': $dark,
);

@each $color, $value in $theme-falcon-btn-colors {
  .btn-falcon-#{$color} {
    
    @if $color != 'default' {
      color: $value !important;
      @include button-variant($white, $white, $white, $white, theme-color-level($color, -10), theme-color-level($color, -10));
    } @else {
      @include button-variant($white, $white, $white, $white);
    }
    
    box-shadow: 0 0 0 1px rgba(43,45,80, 0.1), 0 2px 5px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
    @include hover-focus{
      &:not(.disabled){
        color: darken($value, 8.5%) !important;
        box-shadow: 0 0 0 1px rgba(43,45,80, 0.1), 0 2px 5px 0 rgba(43,45,80, 0.1), 0 3px 9px 0 rgba(43,45,80, 0.08), 0 1px 1.5px 0 rgba($black, 0.08), 0 1px 2px 0 rgba($black, 0.08);
      }
    }
    &:active{
      box-shadow: none;
      color: darken($value, 8.5%) !important;
    }
  }
}
@each $color, $value in $theme-colors{
  .btn-outline-#{$color}{
    &:active{
      background-color: darken($value, 5%) !important;
      border-color: darken($value, 5%) !important;
    }
  }
}
/*-----------------------------------------------
|   Social Outline Buttons
-----------------------------------------------*/
.btn-outline-facebook{ @include button-outline-variant($facebook) }
.btn-outline-twitter { @include button-outline-variant($twitter) }
.btn-outline-google-plus { @include button-outline-variant($google-plus) }


/*-----------------------------------------------
|   Profile Page Introduction
-----------------------------------------------*/
.btn-intro-collapse{
  .less{ display: none; }
  &[aria-expanded="true"]{
    .less{ display: inline; }
    .full{ display: none; }
  }
}