/*-----------------------------------------------
|   Select 2
-----------------------------------------------*/
@import '../../../pages/assets/lib/select2/scss/core.scss';
.select2{ &.select2-container{ width: 100% !important; } }
.select2-container--default{
  .select2-selection--single{
    background-color: $white;
    border-color: $border-color;
    .select2-selection__rendered{  color: $black; }
  }
  .select2-selection--multiple{
    background-color: $white;
    border-color: $input-border-color;
    .select2-selection__rendered{
      color: $black;
      li{ line-height: 1.25rem; }
    }
    .select2-selection__choice__remove{
      color: $black;
      opacity: 0.5;
      @include hover-focus{
        color: $black;
        opacity: 1;
      }
    }
    .select2-selection__choice{
      font-size: map_get($font-sizes, '-1');
      border: 1px solid map_get($grays, '200');
      background-color: $light;
    }
  }
  &.select2-container--focus .select2-selection--multiple{
    border-color: $input-border-color;
  }
  .select2-results__option[aria-selected=true]{
    background-color: $gray-200;
    color: $gray-700;
  }
  .select2-search--inline .select2-search__field{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 7px;
    font-size: map_get($font-sizes, '-1');
  }
  .select2-search--dropdown .select2-search__field{
    border-color: $border-color;
  }
}
.select2-results__option[aria-selected=true]{
  background-color: $gray-200;
  color: $gray-700;
}
.select2-dropdown{
  border-color: $border-color;
  z-index: 1051 !important;
  background-color: $white;
}
.select2-search__field{
  color: $black;
  background-color: $white;
}
.selectpicker option{ visibility: hidden; }
